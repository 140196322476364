.status-badge {
  width: 112px;
  padding: 4px 16px;
  color: #9b9b9b;
  background-color: #d8d8d8;
  border-radius: 40px;
  text-align: center;
}

.status-title {
  margin: 0;
}

.status-badge.paid {
  color: #ffffff;
  background-color: #00b777;
}

.status-badge.pending {
  color: #ffffff;
  background-color: #ffb400;
}