.result-page__container {
  width: 100%;
  display: flex;
}

.result-page__content {
  width: 100%;
}

.content__result {
  min-width: Calc(100vh - 64px);
  overflow: scroll;
}

.result-summary {
  display: flex;
  padding: 16px;
}

.summary-detail {
  text-align: left;
  margin-left: 16px;
}

.summary-detail .title, .summary-detail .description {
  margin: 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table .button {
  margin: 0;
  padding: 0;
}

.table th {
  padding: 16px;
}

.table td {
  padding: 8px 16px;
}

.table-header {
  height: 64px;
}

.table-header th {
  font-weight: normal;
  color: #9b9b9b;
  text-align: left;
  border-bottom: 1px solid #d8d8d8;
}

.table-header th.invoice-amount , .invoice-amount {
  text-align: right;
}

.table-body {
  text-align: left;
}

.table-body .table-row:hover {
  background-color: #e3f5fb;
  cursor: pointer;
}

.table .invoice-status {
  width: 10%;
  min-width: 144px;
}

.table .invoice-amount {
  width: 10%;
  min-width: 144px;
}

.table .invoice-due-date {
  width: 10%;
  min-width: 100px;
}

.table .invoice-description {
  width: 40%;
  min-width: 250px;
  max-width: 290px;
}

.table .download-invoice {
  width: 10%;
  min-width: 95px;
}

.table .download-receipt {
  width: 10%;
  min-width: 81px;
}

.table .download-tax-invoice {
  width: 10%;
  min-width: 113px;
}

.description-box {
  text-align: left;
}

.box-text {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.box-sub-text {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #9b9b9b;
}

.table-row.no-hover:hover {
  background-color: #ffffff;
  cursor: default;
}

.empty-result {
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-result .description {
  margin: 0;
  color: #9b9b9b;
}

.download-invoice, .download-receipt, .download-tax-invoice {
  cursor: default;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2;
}

.ReactModal__Content.ReactModal__Content--after-open {
  position: relative;
  top: 50%;
  text-align: center;
  margin: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.ReactModal__Content.ReactModal__Content--after-open.waiting {
  width: 80px;
  height: 80px;
  margin: auto;
  padding: 2px !important;
  border-radius: 10px !important;
  background-color: #ffffff;
  border: none !important;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2),
              0 6px 30px 5px rgba(0, 0, 0, 0.12),
              0 16px 24px 2px rgba(0, 0, 0, 0.14);
  overflow: hidden;
}
