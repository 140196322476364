#search-page .button {
  margin-left: 16px;
  width: 120px;
}

#search-page .button.primary {
  background-color: #a3c8f3;
}

.logo--big {
  width: 288px;
}